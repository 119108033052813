<app-dialog (close)="onNoClick()">
  <ng-template eskDialogHeader>
    <h3>Edit Property</h3>
  </ng-template>

  <ng-template eskDialogContent>
    <tui-tabs
      [activeItemIndex]="activeTabIndex()"
      (activeItemIndexChange)="activeTabIndex.set($event)"
    >
      <button
        tuiTab
        type="button"
        class="inline-flex items-center justify-center gap-1"
      >
        <fa-icon class="" [icon]="['fadr', 'table']"> </fa-icon>
        Attributes
      </button>
      <button
        tuiTab
        type="button"
        class="inline-flex items-center justify-center gap-1"
      >
        <fa-icon class="" [icon]="['fadr', 'draw-polygon']"></fa-icon>
        Boundary
      </button>
    </tui-tabs>

    @if(activeTabIndex() == 0) {
    <tui-scrollbar>
      <form
        [formGroup]="form"
        class="overflow-y-scroll max-h-[300px] md:max-h-[500px] lg:max-h-[800px] edit-property-form smaller-fields"
      >
        <div class="d-flex form-rows">
          <div class="d-flex top-row">
            <div class="details">
              <div class="form-group-title field-title">
                <h3>Details</h3>
              </div>
              <mat-form-field appearance="outline" color="white">
                <mat-label>Project Name</mat-label>
                <input formControlName="projectName" matInput placeholder="" />
                <mat-error *ngIf="form.get('projectName').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Property Name</mat-label>
                <input formControlName="propertyName" matInput placeholder="" />
                <mat-error *ngIf="form.get('propertyName').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Property Address</mat-label>
                <input
                  formControlName="propertyAddress"
                  matInput
                  placeholder=""
                />
                <mat-error
                  *ngIf="form.get('propertyAddress').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Owner/Operator</mat-label>
                <input
                  formControlName="propertyOwnerName"
                  matInput
                  placeholder=""
                />
                <mat-error
                  *ngIf="form.get('propertyOwnerName').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Source Name</mat-label>
                <input formControlName="sourceName" matInput placeholder="" />
                <mat-error *ngIf="form.get('sourceName').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Process Leader</mat-label>
                <mat-select formControlName="processLeaderID">
                  @for (user of processLeaders(); track $index) {
                  <mat-option [value]="user.id"
                    >{{ user.firstName }} {{ user.lastName }}</mat-option
                  >
                  }
                  <mat-option [value]="null">Other</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="category">
              <div class="form-group-title field-title">
                <h3>Category</h3>
              </div>
              <mat-form-field appearance="outline">
                <mat-label>Primary Class</mat-label>
                <mat-select formControlName="primaryClassID">
                  @for (class of classes(); track $index) {
                  <mat-option [value]="class.propertyClassID">{{
                    class.class
                  }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Secondary Class</mat-label>
                <mat-select formControlName="secondaryClassID">
                  @for (class of classes(); track $index) {
                  <mat-option [value]="class.propertyClassID">{{
                    class.class
                  }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Stage</mat-label>
                <mat-select formControlName="stageID">
                  @for (class of stages(); track $index) {
                  <mat-option [value]="class.propertyStageID">{{
                    class.name
                  }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select formControlName="statusID">
                  @for (class of statuses(); track $index) {
                  <mat-option [value]="class.propertyStatusID">{{
                    class.status
                  }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Suitability</mat-label>
                <mat-select formControlName="suitabilityID">
                  @for (class of suitabilities(); track $index) {
                  <mat-option [value]="class.propertySuitabilityID">{{
                    class.name
                  }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="values">
              <div class="form-group-title field-title">
                <h3>Values</h3>
              </div>

              <mat-form-field appearance="outline">
                <mat-label>Estimated Price ($)</mat-label>
                <input
                  formControlName="price"
                  matInput
                  placeholder=""
                  type="number"
                  class="no-spinners"
                />
                <mat-error *ngIf="form.get('price').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Last Updated</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="updatedAt"
                  readonly
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                  disabled
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>MGA Zone</mat-label>
                <input
                  formControlName="zone"
                  matInput
                  placeholder=""
                  type="number"
                  class="no-spinners"
                />
                <mat-error *ngIf="form.get('zone').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Calculated Area (Ha)</mat-label>
                <input
                  readonly
                  disabled
                  formControlName="areaHaCalculated"
                  matInput
                  placeholder=""
                />
                <mat-error
                  *ngIf="form.get('areaHaCalculated').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Displayed Area (Ha)</mat-label>
                <input
                  formControlName="areaHaDisplay"
                  matInput
                  placeholder=""
                />
                <mat-error
                  *ngIf="form.get('areaHaDisplay').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Water Allocation (ML)</mat-label>
                <input
                  formControlName="waterAllocation"
                  matInput
                  placeholder=""
                  type="number"
                  class="no-spinners"
                />
                <mat-error
                  *ngIf="form.get('waterAllocation').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Last Emissions Assessment</mat-label>
                <input
                  type="date"
                  formControlName="emissionAssessmentYear"
                  matInput
                  placeholder=""
                />
                <mat-error
                  *ngIf="form.get('emissionAssessmentYear').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Total Emissions</mat-label>
                <input
                  formControlName="totalEmissions"
                  matInput
                  placeholder=""
                  pattern="^[0-9]*$"
                />
                <mat-error
                  *ngIf="form.get('totalEmissions').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Environmental Certification</mat-label>
                <input
                  formControlName="environmentalCertification"
                  matInput
                  placeholder=""
                />
                <mat-error
                  *ngIf="form.get('environmentalCertification').errors?.['required']"
                  >This field is required!</mat-error
                >
              </mat-form-field>
            </div>
          </div>
          <div class="d-grid bottom-row">
            <div class="notes">
              <div class="form-group-title">
                <h3>Notes</h3>
              </div>
              <div class="notes-wrapper">
                <p-editor formControlName="notes"></p-editor>
              </div>
            </div>
            <div class="files">
              <div class="form-group-title">
                <h3>Files</h3>
              </div>
              <div class="files-wrapper">
                <div class="upload-section bg-blue">
                  <p-fileUpload
                    #uploader
                    [url]="uploadUrl"
                    (onUpload)="onUpload($event)"
                    chooseLabel="Browse"
                    mode="basic"
                  >
                  </p-fileUpload>
                </div>
                <div class="files-section">
                  <app-file-list
                    (deleteFile)="OnFileDelete($event)"
                    [files]="files"
                  ></app-file-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </tui-scrollbar>
    } @else {

    <section class="grid grid-cols-[1fr_0.25fr] py-3 gap-1">
      <div
        class="relative"
        [id]="spatialStore.state.mapID()"
        olMap
        [layers]="[baseMapGroup, cadasterLayer]"
      >
        <app-toolbar classes="absolute">
          <div
            [class.border]="spatialToolStateMachine()?.matches('modify')"
            [class.border-gray-200]="
              spatialToolStateMachine()?.matches('modify')
            "
            [class.px-2]="spatialToolStateMachine()?.matches('modify')"
            class="inline-flex items-center justify-center transition-all rounded space-x-2"
          >
            <button
              appTooltip="Edit Boundary"
              class="text-gray-600 hover:text-blue-600 focus:outline-none transition-colors w-4"
              (click)="spatialStore.stateMachine.send({ type: 'MODIFY' })"
            >
              <fa-icon
                [class.text-blue-600]="
                  spatialToolStateMachine()?.matches('modify')
                "
                class="hover:text-violet-500"
                [icon]="['fadr', 'vector-polygon']"
              ></fa-icon>
            </button>

            @if(spatialToolStateMachine()?.matches('modify')) {
            <button
              appTooltip="Save Changes"
              class="w-3 text-green-600 focus:outline-none transition-colors"
              (click)="spatialStore.stateMachine.send({ type: 'SAVE_CHANGES' })"
            >
              <fa-icon
                class="hover:text-violet-500"
                [icon]="['fadr', 'check']"
              ></fa-icon>
            </button>

            <button
              appTooltip="Cancel Changes"
              class="w-3 text-red-600 focus:outline-none transition-colors"
              (click)="spatialStore.stateMachine.send({ type: 'CANCEL' })"
            >
              <fa-icon
                class="hover:text-violet-500"
                [icon]="['fadr', 'xmark']"
              ></fa-icon>
            </button>
            }
          </div>
          <div
            class="inline-flex items-center justify-center transition-all rounded space-x-2"
          >
            <button
              class="text-gray-600 hover:text-blue-600 focus:outline-none transition-colors w-3 aspect-square"
              appTooltip="Delete Part"
              (click)="spatialStore.stateMachine.send({ type: 'SELECT' })"
            >
              <fa-icon
                [class.text-blue-600]="
                  spatialToolStateMachine()?.matches('select')
                "
                class="hover:text-violet-500"
                [icon]="['fadr', 'trash']"
              ></fa-icon>
            </button>
          </div>

          <div
            [class.border]="spatialToolStateMachine()?.matches('modify')"
            [class.border-gray-200]="
              spatialToolStateMachine()?.matches('modify')
            "
            [class.px-2]="spatialToolStateMachine()?.matches('modify')"
            class="inline-flex items-center justify-center transition-all rounded space-x-2"
          >
            <button
              appTooltip="Cut boundary"
              class="text-gray-600 hover:text-blue-600 focus:outline-none transition-colors w-4"
              (click)="spatialStore.stateMachine.send({ type: 'DOUGHNUT' })"
            >
              <fa-icon
                [class.text-blue-600]="
                  spatialToolStateMachine()?.matches('doughnut')
                "
                class="hover:text-violet-500"
                [icon]="['fadr', 'scalpel-line-dashed']"
              ></fa-icon>
            </button>

            @if(spatialToolStateMachine()?.matches('doughnut.editing')) {
            <button
              appTooltip="Save Changes"
              class="w-3 text-green-600 focus:outline-none transition-colors"
              (click)="spatialStore.stateMachine.send({ type: 'SAVE_CHANGES' })"
            >
              <fa-icon
                class="hover:text-violet-500"
                [icon]="['fadr', 'check']"
              ></fa-icon>
            </button>

            <button
              appTooltip="Cancel Changes"
              class="w-3 text-red-600 focus:outline-none transition-colors"
              (click)="spatialStore.stateMachine.send({ type: 'CANCEL' })"
            >
              <fa-icon
                class="hover:text-violet-500"
                [icon]="['fadr', 'xmark']"
              ></fa-icon>
            </button>
            }
          </div>
          <div
            [class.border]="spatialToolStateMachine()?.matches('cadastreSelect')"
            [class.border-gray-200]="
              spatialToolStateMachine()?.matches('cadastreSelect')
            "
            [class.px-2]="spatialToolStateMachine()?.matches('cadastreSelect')"
            class="inline-flex items-center justify-center transition-all rounded space-x-2"
          >
          <button
          appTooltip="Select Cadastral Parcels"
          class="text-gray-600 hover:text-blue-600 focus:outline-none transition-colors w-4"
          (click)="spatialStore.stateMachine.send({ type: 'CADASTRE_SELECT' })"
        >
          <fa-icon
            [class.text-blue-600]="
              spatialToolStateMachine()?.matches('cadastreSelect')
            "
            class="hover:text-violet-500"
            [icon]="['fadr', 'box']"
          ></fa-icon>
        </button>

        @if(spatialToolStateMachine()?.matches('cadastreSelect')) {
          <button
            appTooltip="Add Selected Parcel"
            class="w-3 text-green-600 focus:outline-none transition-colors
                   disabled:cursor-not-allowed disabled:opacity-50"
            (click)="spatialStore.stateMachine.send({ type: 'ADD_PARCEL' })"
            [disabled]="cadastreParcelSelected() == false"
          >
            <fa-icon
              class="hover:text-violet-500"
              [icon]="['fadr', 'plus']"
            ></fa-icon>
          </button>
          <button
            appTooltip="Remove Selected Parcel"
            class="w-3 text-red-600 focus:outline-none transition-colors
                   disabled:cursor-not-allowed disabled:opacity-50"
            [disabled]="cadastreParcelSelected() == false"
          (click)="spatialStore.stateMachine.send({ type: 'REMOVE_PARCEL' })"
          >
            <fa-icon
              class="hover:text-violet-500"
              [icon]="['fadr', 'minus']"
            ></fa-icon>
          </button>
        }
          </div>
        </app-toolbar>

        @if(spatialStore.state.mapInstance() != null) {
        <div
          class="absolute bottom-[50px] left-[1%] w-[250px] z-[99] flex flex-col gap-[var(--size-2)] rounded-[var(--radius-2)] min-h-5"
          style="--_child-bg-color: rgba(255, 255, 255, 0.8)"
        >
          <button (click)="updateShowLayers()" class="layer-toggle-btn">
            <i
              [ngClass]="
                !showLayers() ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
              "
            ></i>
          </button>
          <tui-scrollbar>
            <div
              overflowPadding
              style="max-height: 500px"
              [@slideToggle]="showLayers() ? 'open' : 'closed'"
            >
              <tui-accordion [@listAnimation]="popoutLayers()">
                <tui-accordion-item
                  class="no-arrow no-right-border"
                  [showArrow]="false"
                >
                  <app-layer-control
                    [map]="spatialStore.state.mapInstance()"
                    [layer]="spatialStore.cadasterLayer"
                  >
                    <ng-template layerControlHeaderActions> </ng-template>
                  </app-layer-control>
                </tui-accordion-item>
              </tui-accordion>
            </div>
          </tui-scrollbar>

          <map-base-maps-select
            [BaseMapGroup]="baseMapGroup"
            [map]="spatialStore.state.mapInstance()"
          ></map-base-maps-select>
        </div>
        }
      </div>

      <app-edit-history
        (mouseOver)="hover($event)"
        (mouseOut)="hoverEnd($event)"
        [edits]="spatialStore.state.editHistory()"
        (revertClick)="spatialStore.state.revertFeature($event)"
      ></app-edit-history>
    </section>

    }
  </ng-template>

  <ng-template eskDialogFooter>
    <button
      class="flex items-center bg-gray-100 text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-200 transition-colors duration-200"
      (click)="onNoClick()"
    >
      Cancel
    </button>

    <button
      class="flex items-center bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors duration-200"
      (click)="onYesClick()"
    >
      Submit
    </button>
  </ng-template>
</app-dialog>
