import { effect, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { faAnalytics } from '@fortawesome/duotone-regular-svg-icons';
import { feature } from '@turf/turf';
import { createInjectable } from 'ngxtension/create-injectable';
import { debug } from 'ngxtension/debug';
import { filterNil } from 'ngxtension/filter-nil';
import { signalSlice } from 'ngxtension/signal-slice';
import { Collection, Feature, Map } from 'ol';
import { asColorLike } from 'ol/colorlike';
import { click } from 'ol/events/condition';
import { AnyType } from 'ol/expr/expression';
import { Geometry, MultiPoint, MultiPolygon, Polygon } from 'ol/geom';
import { Draw, Modify, Select } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import { SelectEvent } from 'ol/interaction/Select';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import {
  combineLatest,
  EMPTY,
  filter,
  from,
  fromEvent,
  map,
  Observable,
  pairwise,
  Subscribable,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import {
  createEditHistoryItem,
  EditHistoryItem,
} from 'src/app/shared/edit-history/model';
import { Prettify } from 'src/app/shared/features/esk-tool/util';
import { Difference, UnionJSTS } from 'src/app/shared/helpers/transformations';
import {
  Actor,
  assign,
  createActor,
  createMachine,
  EventObject,
  fromEventObservable,
  interpret,
} from 'xstate';

import { pointerMove } from 'ol/events/condition';
import { MapManager } from 'src/app/shared/directives/map/spatial-map.service';
import { v4 as uuid_v4 } from 'uuid';
import { CadastreSelectTool } from './cadastre.select';

const editStyle = [
  new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)',
      }),
      stroke: new Stroke({
        color: 'rgba(128, 0, 128, 1)',
        width: 2,
      }),
    }),
    geometry: function (feature) {
      const geometry = feature.getGeometry();
      if (geometry.getType() === 'MultiPolygon') {
        const multiPolygon = feature as Feature<MultiPolygon>;
        const coordinates = multiPolygon.getGeometry().getCoordinates().flat(2);
        return new MultiPoint(coordinates);
      } else if (geometry.getType() === 'Polygon') {
        const polygon = feature as Feature<Polygon>;
        const coordinates = polygon.getGeometry().getCoordinates()[0];
        return new MultiPoint(coordinates);
      }
      return null;
    },
  }),
  new Style({
    stroke: new Stroke({
      color: 'rgba(0, 128, 0, 1)',
      width: 3,
      lineDash: [5, 5],
    }),
    fill: new Fill({
      color: 'rgba(0, 128, 0, 0.3)',
    }),
  }),
  new Style({
    stroke: new Stroke({
      color: 'rgba(255, 69, 0, 1)',
      width: 4,
    }),
    fill: new Fill({
      color: 'rgba(255, 69, 0, 0.2)',
    }),
    zIndex: 1,
  }),
];
export const EditPropertySpatialState = createInjectable(
  () => {
    const dialogService = inject(ModalService);

    const mapManager = inject(MapManager);

    const mapID = uuid_v4();

    const cadastreSelectTool = inject(CadastreSelectTool);

    const {cadasterLayer, cadasterSelectionLayer, createClickEvent, removeClickEvent,cleanup, selectedFeature} = cadastreSelectTool;

    const instanceChanged$ = toObservable(mapManager.instanceChangedNotifier.listen);

    const boundaryLayer = new VectorLayer({ source: new VectorSource() });

    const modifyLayer = new VectorLayer({
      source: new VectorSource({ features: [] }),
      style: editStyle,
      visible: false,
    });

    const editHistoryLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({ color: 'rgba(255, 255, 0, 0.4)' }),
        stroke: new Stroke({ color: 'yellow', width: 3 }),
        image: new Circle({
          radius: 6,
          fill: new Fill({ color: 'rgba(255, 255, 0, 0.8)' }),
          stroke: new Stroke({ color: '#fff', width: 2 }),
        }),
      }),
    });

    const doughnutSource = new VectorSource();

    const doughnutLayer = new VectorLayer({
      source: doughnutSource,
      visible: false,
      style: editStyle,
    });

    const selectedLayer = new VectorLayer({
      visible: false,
      source: new VectorSource(),
      style: new Style({
        stroke: new Stroke({
          color: 'red',
          width: 2,
        }),
        fill: new Fill({
          color: 'rgba(255,0,0,0.3)',
        }),
      }),
    });

    const doughnutDrawInteraction = new Draw({
      type: 'Polygon',
      source: doughnutSource,
    });

    doughnutDrawInteraction.setActive(false);

    const doughnutModifyInteraction = new Modify({ source: doughnutSource });

    doughnutModifyInteraction.setActive(false);

    const modifyInteraction = new Modify({ source: modifyLayer.getSource() });

    modifyInteraction.setActive(false);

    const selectCollection = new Collection<Feature>();

    const selectInteraction = new Select({
      condition: click,
      features: selectCollection,
      layers: [boundaryLayer],
      style: null,
    });

    selectInteraction.setActive(false);

    const selectHoverInteraction = new Select({
      condition: pointerMove,
      style: null,
    });

    selectHoverInteraction.setActive(false);

    const onSelect$ = fromEvent<SelectEvent>(
      selectInteraction,
      'select'
    ).pipe();

    const onHover$ = fromEvent<SelectEvent>(selectHoverInteraction, 'select');

    selectInteraction.on('select', (event) => {
      selectInteraction.getFeatures().clear();
    });

    const modifyEnd$ = fromEvent<ModifyEvent>(
      modifyInteraction,
      'modifyend'
    ).pipe(filterNil());

    const toolMachine = createMachine(
      {
        id: 'tool',
        context: {
          unsavedChanges: false,
          cadastreFeatureSelected: false,
          cadastreFeature: null,
          lastEvent: undefined,
          mapInstance: null
        },
        invoke: {
          id: 'rxService',
          src: fromEventObservable(() => instanceChanged$.pipe(map(() => mapManager.getInstance(mapID)),filterNil(),map(mapInstance =>  ({ type: 'MAP_INSTANCE_CHANGED', mapInstance:mapInstance  }))))
        },
        on: {
          MAP_INSTANCE_CHANGED: {
            actions: assign({
              mapInstance: ({ context, event }) => event["mapInstance"],
            }),
          }
        },
        initial: 'modify',
        states: {
          idle: {
            entry: 'enterIdle',
            on: {
              MODIFY: 'modify',
              SELECT: 'select',
              DOUGHNUT: 'doughnut',
              CADASTRE_SELECT: 'cadastreSelect',
            },
          },
          select: {
            entry: 'enterSelect',
            exit: 'exitSelect',
            on: {
              MODIFY: 'modify',
              DOUGHNUT: 'doughnut',
              IDLE: 'idle',
              SELECT: 'idle',
              CADASTRE_SELECT: 'cadastreSelect',
            },
          },
          cadastreSelect: {
            entry: ['enterCadastreSelect'],
            exit: 'exitCadastreSelect',
            invoke: {
              id: 'cadasterFeatureChanged',
              src: fromEventObservable(() =>
                selectedFeature.asObservable().pipe(debug("SelectedFeature"),map((feature) => {
                  if(feature == null)
                    return { type: 'CADASTRE_FEATURE_DESELECTED', feature }

                  return { type: 'CADASTRE_FEATURE_SELECTED', feature }
                }))
              ),
            },
            on: {
              CADASTRE_FEATURE_SELECTED: {
                actions: assign({
                  cadastreFeatureSelected: (ctx, event) => true,
                  cadastreFeature: ({event}): any => event["feature"]
                }),
              },
              CADASTRE_FEATURE_DESELECTED: {
                actions: assign({
                  cadastreFeatureSelected: (ctx, event) => false,
                  cadastreFeature: ({event}): any => event["feature"]
                }),
              },
              REMOVE_PARCEL: {
                actions: [
                  'removeSelectedParcel',
                  'exitCadastreSelect'
                ],
                target: 'idle',
              },
              ADD_PARCEL: {
                actions: [
                  'addSelectedParcel',
                  'exitCadastreSelect'
                ],
                target: 'idle',
              },
              MODIFY: 'modify',
              DOUGHNUT: 'doughnut',
              IDLE: 'idle',
              CADASTRE_SELECT: 'idle',
            }
          },
          doughnut: {
            type: 'compound',
            initial: 'drawing',
            states: {
              drawing: {
                on: {
                  TO_EDIT: {
                    actions: assign({
                      unsavedChanges: (ctx, event) => true,
                    }),
                    target: 'editing',
                  },
                },
                entry: 'enterDraw',
                exit: 'exitDraw',
                invoke: {
                  id: 'mouseClickLogic',
                  src: fromEventObservable(() =>
                    fromEvent(doughnutDrawInteraction, 'drawend').pipe(
                      debug('Draw End'),
                      map(() => ({ type: 'TO_EDIT' }))
                    )
                  ),
                },
              },
              editing: {
                entry: 'enterEdit',
              },
            },
            on: {
              EXIT_DOUGHNUT: [
                {
                  guard: 'hasUnsavedChanges',
                  actions: ['storeLastEvent'],
                  target: 'confirmLeavingDoughnut',
                },
                { target: 'idle', actions: ['exitDoughnut'] },
              ],
              DOUGHNUT: [
                {
                  guard: 'hasUnsavedChanges',
                  actions: ['storeLastEvent'],
                  target: 'confirmLeavingDoughnut',
                },
                { target: 'idle', actions: ['exitDoughnut'] },
              ],
              SAVE_CHANGES: {
                actions: [
                  'saveDoughnutChanges',
                  'clearUnsavedChanges',
                  'exitDoughnut',
                ],
                target: 'idle',
              },
              CANCEL: {
                actions: ['clearUnsavedChanges', 'resetDoughnut'],
                target: 'doughnut.drawing',
              },
              MODIFY: [
                {
                  guard: 'hasUnsavedChanges',
                  actions: ['storeLastEvent'],
                  target: 'confirmLeavingDoughnut',
                },
                { target: 'modify', actions: ['exitDoughnut'] },
              ],
              CADASTRE_SELECT:
              [
                {
                  guard: 'hasUnsavedChanges',
                  actions: ['storeLastEvent'],
                  target: 'confirmLeavingDoughnut',
                },
                {target: 'cadastreSelect'}
              ],
              SELECT: [
                {
                  guard: 'hasUnsavedChanges',
                  actions: ['storeLastEvent'],
                  target: 'confirmLeavingDoughnut',
                },
                { target: 'select', actions: ['exitDoughnut'] },
              ],
            },
          },
          modify: {
            entry: 'enterModify',
            exit: 'exitModify',
            invoke: {
              id: 'modifyChange',
              src: fromEventObservable(() =>
                modifyEnd$.pipe(map(() => ({ type: 'UNSAVED_CHANGES' })))
              ),
            },
            on: {
              MODIFY: [
                {
                  guard: 'hasUnsavedChanges',
                  actions: ['storeLastEvent'],
                  target: 'confirmLeavingModify',
                },
                { target: 'idle' },
              ],
              CADASTRE_SELECT:
              [
                {
                  guard: 'hasUnsavedChanges',
                  actions: ['storeLastEvent'],
                  target: 'confirmLeavingModify',
                },
                {target: 'cadastreSelect'}
              ],
              IDLE: [
                {
                  guard: 'hasUnsavedChanges',
                  actions: ['storeLastEvent'],
                  target: 'confirmLeavingModify',
                },
                { target: 'idle' },
              ],
              SELECT: [
                {
                  guard: 'hasUnsavedChanges',
                  actions: ['storeLastEvent'],
                  target: 'confirmLeavingModify',
                },
                { target: 'select' },
              ],
              UNSAVED_CHANGES: {
                actions: assign({
                  unsavedChanges: (ctx, event) => true,
                }),
              },

              SAVE_CHANGES: {
                actions: [
                  'saveModifyChanges',
                  'clearUnsavedChanges',
                  'exitModify',
                ],
                target: 'idle',
              },
              CANCEL: {
                actions: ['resetModify', 'clearUnsavedChanges'],
              },
              DOUGHNUT: [
                {
                  guard: 'hasUnsavedChanges',
                  actions: ['storeLastEvent'],
                  target: 'confirmLeavingModify',
                },
                {
                  target: 'doughnut',
                },
              ],
            },
          },
          confirmLeavingModify: {
            entry: 'openUnsavedChangesDialog',
            on: {
              CANCEL: 'modify',
              CONTINUE: {
                target: 'modify',
                actions: [
                  'clearUnsavedChanges',
                  'sendLastEvent',
                  'resetModify',
                  'exitModify',
                ],
              },
              MODIFY: 'idle',
              IDLE: 'idle',
              SELECT: 'select',
              DOUGHNUT: 'doughnut',
            },
          },

          confirmLeavingDoughnut: {
            entry: 'openUnsavedChangesDialog',
            on: {
              CANCEL: 'doughnut.editing',
              CONTINUE: {
                target: 'doughnut',
                actions: [
                  'clearUnsavedChanges',
                  'sendLastEvent',
                  'exitDoughnut',
                ],
              },
              MODIFY: 'idle',
              IDLE: 'idle',
              SELECT: 'select',
              DOUGHNUT: 'doughnut',
            },
          },
        },
      },
      {
        actions: {
          storeLastEvent: assign({
            lastEvent: ({ event }) => event,
          }),
          sendLastEvent: ({ context, self }) => {
            if (context.lastEvent) {
              self.send(context.lastEvent);
            }
          },
          clearUnsavedChanges: assign({
            unsavedChanges: false,
          }),
          forwardOriginalEvent: ({ self, event }) => {
            self.send(event);
          },
          enterIdle: () => console.log('Now in idle mode'),
          enterCadastreSelect: ({context}) => {

            createClickEvent(context.mapInstance);
            cadasterSelectionLayer.setVisible(true);

          },
          exitCadastreSelect: () => {
            cleanup()
            cadasterSelectionLayer.setVisible(false);

          },
          removeSelectedParcel: ({context}) => {

            const selectedFeature = context.cadastreFeature;

            if(selectedFeature == null)
              return;

            const feature = state.feature();



            const difference = Difference(feature, selectedFeature);

            state.updateFeature([difference, 'cut']);

          },
          addSelectedParcel: ({context}) => {

            const selectedFeature = context.cadastreFeature;

            if(selectedFeature == null)
              return;

            const feature = state.feature();



            const union = UnionJSTS([feature, selectedFeature]);

            state.updateFeature([union, 'add']);

          },
          enterSelect: () => {
            selectHoverInteraction.setActive(true);
            selectInteraction.setActive(true);
            selectedLayer.setVisible(true);
          },
          exitSelect: () => {
            selectHoverInteraction.setActive(false);
            selectInteraction.setActive(false);
            selectedLayer.setVisible(false);
          },
          enterModify: () => {
            modifyInteraction.setActive(true);
            modifyLayer.setVisible(true);
          },
          exitModify: (args) => {
            if (args.context.unsavedChanges) {
              return;
            }

            modifyInteraction.setActive(false);
            modifyLayer.setVisible(false);
          },

          saveModifyChanges: (args) => {
            const modifiedFeature = modifyLayer.getSource().getFeatures()[0];

            state.updateFeature([modifiedFeature, 'modify']);
          },
          saveDoughnutChanges: () => {
            const modifiedFeature = doughnutLayer.getSource().getFeatures()[0];
            const feature = state.feature();

            const difference = Difference(feature, modifiedFeature);

            state.updateFeature([difference, 'cut']);
          },
          enterDraw: () => {
            doughnutLayer.setVisible(true);
            doughnutDrawInteraction.setActive(true);
          },
          exitDoughnut: () => {
            doughnutLayer.getSource().clear();
            doughnutLayer.setVisible(false);
          },
          exitDraw: () => doughnutDrawInteraction.setActive(false),
          enterEdit: () => doughnutModifyInteraction.setActive(true),
          resetModify: () => {
            const feature = state.feature();

            if (feature == null) return;

            const modifySource = modifyLayer.getSource();

            modifySource.clear();

            modifySource.addFeature(feature.clone());
          },
          resetDoughnut: () => {
            doughnutLayer.getSource().clear();
          },
          openUnsavedChangesDialog: ({ self, context }, event) => {
            dialogService
              .showConfirmation(
                'Are you sure you want to change tools? you may have unsaved changes.'
              )
              .pipe(
                filter((result) => result.ok),
                map((result) => result.val)
              )
              .subscribe((confirmed) => {
                if (confirmed) {
                  self.send({ type: 'CONTINUE' });
                } else {
                  self.send({ type: 'CANCEL' });
                }
              });
          },
        },
        guards: {
          hasUnsavedChanges: (args, event) => {
            console.log('In Guard');
            return args.context.unsavedChanges === true;
          },
        },
      }
    );

    const stateMachine = createActor(toolMachine).start();

    const StateValue = from(stateMachine).pipe(map((machine) => machine.value));


    const initialState = {
      selectedFeature: null as Feature<any> | null,
      selectedPolygonIndex: -1 as number,
      editHistory: [],
      feature: null,
      mapInstance: null as Map,
      mapID: mapID
    };

    onSelect$.pipe(takeUntilDestroyed(),debug("On Select")).subscribe((event) => {
      const clickCoordinate = event.mapBrowserEvent.coordinate;
      if (event.selected.length > 0) {
        const feature: Feature = event.selected[0];
        const geometry = feature.getGeometry();

        if (geometry && geometry.getType() === 'MultiPolygon') {
          const multiPolygon = geometry as MultiPolygon;
          const polygons = multiPolygon.getPolygons();

          for (let i = 0; i < polygons.length; i++) {
            if (polygons[i].intersectsCoordinate(clickCoordinate)) {
              const newPolygons = polygons.slice();
              newPolygons.splice(i, 1);
              let newGeometry = null;

              if (newPolygons.length > 1) {
                newGeometry = new MultiPolygon(newPolygons);
              } else if (newPolygons.length == 1) {
                newGeometry = newPolygons[0];
              } else {
                newGeometry = null;
              }

              if (newGeometry == null) {
                return;
              }
              const newFeature = new Feature({
                geometry: newGeometry,
              });

              state.updateFeature([newFeature, 'part-delete']);

              stateMachine.send({ type: 'IDLE' });
              break;
            }
          }
        }
      }
    });

    const state = signalSlice({
      initialState: initialState,
      sources: [
        (state) => instanceChanged$.pipe(map(() => mapManager.getInstance(state().mapID)),map(mapInstance => ({mapInstance}))),
        (state) =>
          onHover$.pipe(
            map((event) => {
              const clickCoordinate = event.mapBrowserEvent.coordinate;
              if (event.selected.length > 0) {
                const feature: Feature = event.selected[0];
                const geometry = feature.getGeometry();

                if (geometry && geometry.getType() === 'MultiPolygon') {
                  const multiPolygon = geometry as MultiPolygon;
                  const polygons = multiPolygon.getPolygons();

                  for (let i = 0; i < polygons.length; i++) {
                    if (polygons[i].intersectsCoordinate(clickCoordinate)) {
                      if (state().selectedPolygonIndex == i)
                        return {
                          selectedFeature: null,
                          selectedPolygonIndex: -1,
                        };

                      const highlightFeature = new Feature({
                        geometry: polygons[i].clone(),
                      });

                      return {
                        selectedFeature: highlightFeature,
                        selectedPolygonIndex: i,
                      };

                      break;
                    }
                  }
                } else
                  return { selectedFeature: feature, selectedPolygonIndex: -1 };
              }

              return null;
            }),
            filterNil(),
            map((model) => ({ ...model }))
          ),
        (state) =>
          StateValue.pipe(
            pairwise(),
            map(([old, curr]) => {
              if (old === 'select') {
                return { selectedPolygonIndex: -1, selectedFeature: null };
              }

              return { ...state() };
            })
          ),
      ],
      actionSources: {
        setFeature: (state, action$: Observable<Feature>) =>
          action$.pipe(
            debug('Setting Feature'),
            map((feature) => ({ feature }))
          ),
        addCadasterEvent: (state, action$:Observable<void>) => action$.pipe(tap(() => {
          createClickEvent(state().mapInstance)
        }),switchMap(() => EMPTY)),
        revertFeature: (state, action$: Observable<EditHistoryItem>) =>
          action$.pipe(
            map((editItem) => ({
              feature: new Feature(editItem.feature.getGeometry()),
              editHistory: state().editHistory.filter(
                (item) => item.id !== editItem.id
              ),
            }))
          ),
        updateFeature: (
          state,
          action$: Observable<[modifiedFeature: any, type: any]>
        ) =>
          action$.pipe(
            map(([modifiedFeature, type]) => ({
              feature: new Feature(modifiedFeature.getGeometry()),
              editHistory: [
                ...state().editHistory,
                createEditHistoryItem(state().feature, type),
              ],
            }))
          ),
        patchState: (state, action$: Observable<{ feature: any }>) =>
          action$.pipe(map((val) => ({ ...val }))),
      },
    });

    effect(() => {
      const feature = state.feature();

      const modifySource = modifyLayer.getSource();
      modifySource.clear();
      boundaryLayer.getSource().clear();
      selectCollection.clear();
      if (feature == null) return;

      boundaryLayer.getSource().addFeature(feature);
      modifySource.addFeature(feature.clone());
      selectCollection.extend(feature.clone());
    });

    effect(() => {
      const selectedFeature = state.selectedFeature();

      selectedLayer.getSource().clear();
      selectInteraction.getFeatures().clear();

      if (selectedFeature == null) return;

      selectedLayer.getSource().addFeature(selectedFeature);
    });


    combineLatest([state.feature$.pipe(filterNil(),take(1)), state.mapInstance$.pipe(filterNil())]).subscribe(([feature, mapInstance]) => {
      mapInstance.getView().fit(feature.getGeometry(), {
      })
    });

    effect(() => {
      const edits = state.editHistory();


      editHistoryLayer.getSource().clear();

    })

    effect(() => {
      const mapInstance = state.mapInstance();


      console.log("we are in the map instance")
      if(mapInstance == null)
        return;

      mapInstance.addInteraction(modifyInteraction);
      mapInstance.addInteraction(doughnutDrawInteraction);
      mapInstance.addInteraction(doughnutModifyInteraction);
      mapInstance.addInteraction(selectHoverInteraction);
      mapInstance.addInteraction(selectInteraction);
      mapInstance.addLayer(editHistoryLayer)
      mapInstance.addLayer(boundaryLayer)
      mapInstance.addLayer(selectedLayer);
      mapInstance.addLayer(modifyLayer);
      mapInstance.addLayer(doughnutLayer);
      mapInstance.addLayer(cadasterLayer);



        mapInstance.on('moveend', () => {

          const currentZoom = mapInstance.getView().getZoom();
          const cadasterMinZoom = cadasterLayer.getMinZoom();

        const atRequiredZoom = currentZoom >= cadasterMinZoom;

        const layerArray = mapInstance.getLayers().getArray();
        const layerAdded = layerArray.includes(cadasterSelectionLayer);

        if (!atRequiredZoom && layerAdded) {
          mapInstance.removeLayer(cadasterSelectionLayer);
        } else if(!layerAdded) {
          mapInstance.addLayer(cadasterSelectionLayer);
        }
    })
  });


    return {
      modifyInteraction,
      modifyLayer,
      selectInteraction,
      selectHoverInteraction,
      state,
      selectedLayer,
      stateMachine,
      doughnutLayer,
      doughnutDrawInteraction,
      doughnutModifyInteraction,
      boundaryLayer,
      editHistoryLayer,
      cadasterLayer
    };
  },
  { providedIn: 'scoped' }
);
