import { createInjectable } from "ngxtension/create-injectable";
import { Map as OlMap } from "ol";
import { createNotifier } from 'ngxtension/create-notifier';


export const MapManager = createInjectable(() => {

  const instances = new Map<string, OlMap>();
  const instanceChangedNotifier = createNotifier();

  const registerInstance = (id: string, instance:OlMap) => {
    instances.set(id,instance);
    instanceChangedNotifier.notify();
  }

  const getInstance = (id: string) => instances.get(id);

  const removeInstance = (id: string) => {
    instances.delete(id);
    instanceChangedNotifier.notify();
  } ;

  return {registerInstance, getInstance, removeInstance, instanceChangedNotifier}
});
