import { Directive, effect, ElementRef, inject, input, model } from '@angular/core';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import { MapManager } from './spatial-map.service';
import { v4 as uuid_v4 } from 'uuid';
import BaseLayer from 'ol/layer/Base';

@Directive({
  selector: '[olMap]',
  host:{
    class: 'h-full w-full'
  },
  standalone: true
})
export class MapDirective {

  private readonly manager = inject(MapManager);

  protected layers = input<BaseLayer[]>([])

  protected mapID = model<string>(null, {alias: 'id'});

  private map: Map;
  private resizeObserver!: ResizeObserver;


  constructor(private el: ElementRef<HTMLElement>) {

effect(() => {
  const layers = this.layers();

  this.map.setLayers(layers)
})

    this.map = new Map({
      target: this.el.nativeElement,
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      view: new View({
        center: [0, 0],
        zoom: 2
      }),
      controls: []
    });

    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.map.updateSize()
        this.map.render();
      }
    });


    this.resizeObserver.observe(el.nativeElement);

    effect(() => {
      const mapID = this.mapID();

      if(mapID == null)
        return;

      this.manager.registerInstance(mapID,this.map);

    })


  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.setTarget(undefined);
    }

    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    this.manager.removeInstance(this.mapID());
  }


}
