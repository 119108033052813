import { CommonModule } from '@angular/common';
import { Component, input, OnInit, output } from '@angular/core';

@Component({
    selector: 'app-edit-history',
    imports: [CommonModule],
    template: `
<div class="bg-white border border-gray-200 rounded-md h-full p-4 w-full max-w-3xl mx-auto">
  <h2 class="text-xl font-bold mb-4">Edit History</h2>

  <div class="h-[600px] overflow-y-auto">
    <ul class="flex flex-col gap-4">
      @for(edit of edits(); track edit.id)
        {
          <li
              (mouseover)="mouseOver.emit(edit)"
    (mouseout)="mouseOut.emit(edit)"


          (hover)="hover.emit(edit)" (hoverEnd)="hoverEnd.emit(edit)



          "
        class="bg-gray-50 p-3 rounded hover:bg-gray-100 transition-colors flex flex-col gap-2"
      >
        <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div>
            <div class="text-sm font-medium text-gray-700">
            </div>
            <div class="text-xs text-gray-500">
              {{ edit.timestamp | date:'medium' }}
            </div>
          </div>
          <div>
            <span class="inline-block px-2 py-1 text-xs font-semibold rounded bg-blue-100 text-blue-800">
              {{ edit.editType }}
            </span>
          </div>
        </div>

        <div>
          <button
            class="text-xs text-blue-600 hover:underline"
          >
          </button>

          <div class="mt-2 text-gray-600 text-xs whitespace-pre-wrap break-all">

          </div>
        </div>

        <div class="mt-2">
          <button
            class="bg-red-500 text-white px-3 py-1 text-xs rounded hover:bg-red-600"
            (click.stop)="revertClick.emit(edit)"
          >
            Revert to this state
          </button>
        </div>
      </li>
        }
      @empty{
        <div class="text-gray-500 text-sm text-center">
      No edits have been recorded yet.
    </div>
      }
    </ul>
    </div>
    </div>
`,
}
)
export class EditHistoryComponent implements OnInit {

  edits = input([ ]);

  revertClick = output();

  mouseOut = output()
  mouseOver = output();




  constructor() { }

  ngOnInit() {
  }

}
