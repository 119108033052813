import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayDate',
  standalone:true
})
export class DisplayDatePipe implements PipeTransform {

  transform(value: Date, ...args: unknown[]): string {
    const formattedDate = value.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return formattedDate;
  }

}
