import { Feature } from 'ol';
import { v4 as uuidv4 } from 'uuid';

export const createEditHistoryItem = (
  feature: Feature,
  editType: EditType
): EditHistoryItem => {
  return {
    id: uuidv4(),
    feature,
    editType,
    timestamp: new Date()
  }
}

export type EditType = 'modify' | 'part-delete' | 'cut' | 'add' | 'unknown';

export interface EditHistoryItem {
  id: string;
  feature: Feature;
  editType: EditType;
  timestamp: Date;
}
