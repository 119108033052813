import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, signal, computed } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogComponent, DialogHeaderDirective, DialogFooterDirective, DialogContentDirective } from 'src/app/shared/features/dialog';
import { Result, Ok } from 'ts-results';
import { LayerDTO } from '../../data-access/feature-layers.service';
import { SelectionModel } from '@angular/cdk/collections';
import { TuiCheckbox } from '@taiga-ui/kit';
import { toSignal } from '@angular/core/rxjs-interop';
import {map} from "rxjs";
import { TuiScrollbar } from '@taiga-ui/core';

@Component({
    selector: 'app-clip-selected-layers',
    imports: [DialogComponent, DialogHeaderDirective, DialogFooterDirective, DialogContentDirective, CommonModule, ReactiveFormsModule, FormsModule, TuiCheckbox, TuiScrollbar],
    template: `
 <app-dialog (close)="onNoClick()">
   <ng-template eskDialogHeader>
     <h3>Clip Feature Layers</h3>
   </ng-template>

   <ng-template eskDialogFooter>
     <button class="button button--muted button--xs" (click)="onNoClick()">Cancel</button>
     <button class="button button--green-fill button--xs" (click)="onYesClick()">Continue</button>
   </ng-template>
   <ng-template eskDialogContent>
   <form class="max-w-md mx-auto p-6">
   <p class="mb-4">
    The feature/s you created or modified intersects with the following layers.
    Select the layers you wish to perform an intersection with,
    or continue without intersecting.
  </p>

  <div class="flex items-center py-2.5 border-b border-gray-200">
  <div class="flex items-center justify-between flex-grow">
    <label class="flex items-center gap-2 font-medium text-gray-700">
      <input
        type="checkbox"
        tuiCheckbox
        [ngModelOptions]="{ standalone: true }"
        [ngModel]="allSelected()"
        (ngModelChange)="toggleAll($event)"
        class="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
      />
      Select All
    </label>
  </div>
</div>

<tui-scrollbar>
  <div class="max-h-[400px] overflow-y-scroll">
  @for(layer of layers(); track layer.layerID)
      {
        <div
    class="flex items-center py-2.5 border-b border-gray-200"
  >

    <div class="flex items-center justify-between flex-grow">
      <label
        [for]="'isLandUse-' + i"
        class=" flex items-center gap-2 font-medium text-gray-700"
      >
      <input
      [ngModelOptions]="{standalone: true}"
        type="checkbox"
        tuiCheckbox
        [ngModel]="selectionModel.isSelected(layer.layerID)"
        (ngModelChange)="selectionModel.toggle(layer.layerID)"
        class="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
      />

        {{ layer.name }}
      </label>



      <span>
  Is Landuse layer:
  <span [ngClass]="isLandUse ? 'text-green-500' : 'text-red-500'">
    {{ isLandUse ? 'Yes' : 'No' }}
  </span>
</span>
    </div>
  </div>
      }

</div>

</tui-scrollbar>


 </form>
   </ng-template>
 </app-dialog>
 `
})
export class ClipSelectedLayersComponent {

  selectionModel = new SelectionModel<number>(true);

  selected = toSignal(this.selectionModel.changed.pipe(map(() => this.selectionModel.selected)),{initialValue: []});

  allSelected = computed(() => {

    const layers = this.layers();
    const selected = this.selected();
    const total = layers.length;
    const selectedCount = selected.length;


    return selectedCount === total && total > 0;
  });

  toggleAll(select: boolean)
  {
    if(!select)
    {
      this.selectionModel.clear();

      return;
    }

    const layerIDs = this.layers().map(layer => layer.layerID);

    this.selectionModel.select(...layerIDs);
  }


  layers = signal<LayerDTO[]>([])
  constructor(
    @Inject(DIALOG_DATA) public data:LayerDTO[],
    public dialogRef: DialogRef<Result<number[] | null,Error>,ClipSelectedLayersComponent>
  ) {
    dialogRef.disableClose = true;
    this.layers.set(this.data);
  }

   onNoClick(): void {
     this.dialogRef.close(Ok(null));
   }

   onYesClick(): void {
     this.dialogRef.close(Ok(this.selectionModel.selected));
   }

}
