import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  Renderer2,
  Signal,
  ViewChild,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ButtonModule } from 'primeng/button';
import { Observable, delay, filter, from, map, startWith } from 'rxjs';

import { InputTextModule } from 'primeng/inputtext';

import { DropdownModule, Dropdown } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { FileUploadModule, UploadEvent } from 'primeng/fileupload';
import { EditorModule } from 'primeng/editor';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DisplayDatePipe } from 'src/app/shared/pipes/display-date.pipe';
import { CalendarModule } from 'primeng/calendar';
import { MaterialModule } from 'src/app/material/material.module';
import { CommonModule } from '@angular/common';
import { ToastService } from 'src/app/@core/services/toast/toast.service';
import { FileListComponent } from '../../ui/file-list/file-list.component';
import { PropertiesService } from '../../data-access/properties.service';
import { PropertyClassService } from 'src/app/@core/property-class/data-access/services/property-class.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { PropertyStageService } from 'src/app/@core/property-stage/data-access/services/property-stage.service';
import { PropertySuitabilityService } from 'src/app/@core/property-suitability/data-access/services/property-suitability.service';
import { PropertyStatusService } from 'src/app/@core/property-status/data-access/services/property-status.service';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { UsersService } from 'src/app/@admin/features/users/data-access/users.service';
import { environment } from 'src/environments/environment';
import { Err, Ok, Result } from 'ts-results';
import { TuiAccordion, TuiTabs } from '@taiga-ui/kit';
import { MapDirective } from 'src/app/shared/directives/map/map.directive';
import { DialogComponent, DialogContentDirective, DialogFooterDirective, DialogHeaderDirective } from 'src/app/shared/features/dialog';
import { MapManager } from 'src/app/shared/directives/map/spatial-map.service';


import { Modify, Select } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { Geometry, MultiPoint, MultiPolygon } from 'ol/geom';
import { Style, Fill, Stroke, Circle, Text } from 'ol/style';
import { CovertWKTFeature } from 'src/app/shared/helpers/transformations';
import { click } from 'ol/events/condition';
import { EditPropertySpatialState } from './edit-property.spatial';
import { ToolbarComponent } from 'src/app/shared/components/toolbar/toolbar.component';
import { EditHistoryComponent } from 'src/app/shared/edit-history/edit-history.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TuiTextareaModule } from '@taiga-ui/legacy';
import { MapService } from 'src/app/map/data-access/map.service';
import LayerGroup from 'ol/layer/Group';
import { BasemapsService } from 'src/app/map/data-access/Basemaps.service';
import { BasemapsSelectComponent } from 'src/app/shared/components/basemaps-select/basemaps-select.component';
import { LayersService } from '../../../layers/data-access/services/layers.service';
import { TuiScrollbar } from '@taiga-ui/core';
import { StyleRendererComponent } from 'src/app/shared/components/style-renderer/style-renderer.component';
import { OverflowPaddingDirective } from 'src/app/shared/directives/overflow-padding.directive';
import { LayerControlActionsDirective } from 'src/app/shared/features/layer-control/layer-control-actions.directive';
import { LayerControlHeaderActionsDirective } from 'src/app/shared/features/layer-control/layer-control-header-actions.directive';
import { LayerControlPreviewDirective } from 'src/app/shared/features/layer-control/layer-control-preview.directive';
import { LayerControlComponent } from 'src/app/shared/features/layer-control/layer-control.component';
import { LayerLegendComponent } from 'src/app/shared/features/layer-legend/layer-legend.component';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ListAnimation } from 'src/app/shared/animations/animations';
import { LayerControlServiceService } from 'src/app/shared/features/layer-control/LayerControlService.service';
import { MVT } from 'ol/format';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { ConvertFeatureToWKT } from 'src/app/shared/helpers/transformations';
import { EditHistoryItem } from 'src/app/shared/edit-history/model';
import { unByKey } from 'ol/Observable';
import { CadastreSelectTool } from './cadastre.select';

export class UpdatePropertyEvent {
  update: boolean;
  model?: any;

  constructor(update, model = null) {
    this.update = update;
    this.model = model;
  }

  static update(model: any) {
    return new UpdatePropertyEvent(true, model);
  }

  static cancel() {
    return new UpdatePropertyEvent(false);
  }
}

@Component({
    selector: 'app-edit-property',
    templateUrl: './edit-property.component.html',
    styleUrls: ['./edit-property.component.css'],
    imports: [
        TooltipDirective,
        ButtonModule,
        InputTextModule,
        ReactiveFormsModule,
        DropdownModule,
        CommonModule,
        FormsModule,
        CalendarModule,
        InputMaskModule,
        FileUploadModule,
        DisplayDatePipe,
        MaterialModule,
        FileListComponent,
        EditorModule,
        TuiTabs,
        MapDirective,
        DialogComponent,
        DialogHeaderDirective,
        DialogFooterDirective,
        DialogContentDirective,
        ToolbarComponent,
        FontAwesomeModule,
        EditHistoryComponent,
        TuiTextareaModule,
        BasemapsSelectComponent,
                TuiAccordion,
                LayerControlComponent,
                LayerControlPreviewDirective,
                LayerControlActionsDirective,
                LayerControlHeaderActionsDirective,
                StyleRendererComponent,
                LayerLegendComponent,
                TuiScrollbar,
                OverflowPaddingDirective,
    ],
    providers: [UsersService, EditPropertySpatialState, LayerControlServiceService, CadastreSelectTool],
    host: {
        '[class]': 'computedClasses()',
    },
      animations: [ListAnimation, trigger('slideToggle', [
                state('open', style({
                    height: '*',
                    opacity: 1,
                })),
                state('closed', style({
                    height: '0px',
                    opacity: 0,
                    overflow: 'hidden'
                })),
                transition('open <=> closed', [
                    animate('300ms ease-in-out')
                ])
            ])]
})
export class EditPropertyComponent {

  protected readonly computedClasses = computed(() => {
    const activeTabIndex = this.activeTabIndex();

    if(activeTabIndex == 1)
    {
      return "inline-block w-[65dvw] h-[75dvh] ";
    }

    return "inline-block";
  })

  private readonly dialogRef: DialogRef<Result<any | void, Error>,EditPropertyComponent> = inject(DialogRef<Result<{} | void, Error>,EditPropertyComponent>);

  private readonly toastService: ToastService = inject(ToastService);
  private readonly propertiesService: PropertiesService =
    inject(PropertiesService);
  private readonly propertyClassService: PropertyClassService =
    inject(PropertyClassService);
  private readonly propertyStageService: PropertyStageService =
    inject(PropertyStageService);
  private readonly propertySuitabilityService: PropertySuitabilityService =
    inject(PropertySuitabilityService);
  private readonly propertyStatusService: PropertyStatusService = inject(
    PropertyStatusService
  );
  public readonly layersService: LayersService = inject(LayersService);

  private readonly baseMapsService = inject(BasemapsService);


    private readonly manager = inject(MapManager);
    private readonly spatialStore = inject(EditPropertySpatialState);

  private readonly usersService: UsersService = inject(
    UsersService
  );

  form = new FormGroup({
    propertyID: new FormControl(null),

    projectName: new FormControl(null),
    propertyName: new FormControl(null),
    propertyAddress: new FormControl(null),
    propertyOwnerName: new FormControl(null),
    processLeaderID: new FormControl(null),
    sourceName: new FormControl(null),
    sourceEmail: new FormControl(null),
    sourcePhone: new FormControl(null),
    geom: new FormControl(''),
    primaryClassID: new FormControl(null),
    secondaryClassID: new FormControl(null),
    stageID: new FormControl(null),
    statusID: new FormControl(null),
    suitabilityID: new FormControl(null),

    // <> doesn't works here
    // /^(\d+[.]\d+|\d+)$/)] matches a number with or without a decimal point
    price: new FormControl<number | null>(null),
    updatedAt: new FormControl({ disabled: true, readOnly: true }), // it doesn't seem setting disabled has any affect
    zone: new FormControl(null),
    areaHaCalculated: new FormControl({ disabled: true, readOnly: true }),
    areaHaDisplay: new FormControl(null),
    waterAllocation: new FormControl(null),
    totalEmissions: new FormControl(null),
    emissionAssessmentYear: new FormControl(null),
    environmentalCertification: new FormControl(null),

    notes: new FormControl(null),

    createdAt: new FormControl(null),
    dateModified: new FormControl(null),
    listingDate: new FormControl(null),
    mapLabel: new FormControl(null),
    propertyCadastralID: new FormControl(null),
    region: new FormControl(null),
    state: new FormControl(null),
  });

  files: any[] = [];
  classes = toSignal(this.propertyClassService.getAll());
  statuses = toSignal(this.propertyStatusService.getAll());
  stages = toSignal(this.propertyStageService.getAll());
  suitabilities = toSignal(this.propertySuitabilityService.getAll());
  processLeaders = toSignal(this.usersService.GetProcessLeaders())
  uploadUrl: string;
  isDirty: Signal<any>;

  activeTabIndex = signal(0);
  mapInstanceID = signal<string>('');
  selection: Map<any, Feature[]> = new Map();
  selectedPolygonIndex = signal(-1)


  spatialToolStateMachine = toSignal(from(this.spatialStore.stateMachine).pipe(startWith(this.spatialStore.stateMachine.getSnapshot())));

  cadastreParcelSelected = computed(() => this.spatialToolStateMachine().context.cadastreFeatureSelected);

  popoutLayers = toSignal(this.layersService.poppedOutLayers);
  showLayers = signal(true);
  updateShowLayers() {
    this.showLayers.set(this.showLayers() ? false : true);
  }
  showContextMenu = signal(false);
  public baseMapGroup = new LayerGroup({
    layers: [],
    name: 'Background Maps',
    fold: 'open',
  } as any);

  cadasterLayer = new VectorTileLayer({
    properties: {title: 'Cadastre'},
    declutter:true,
    source: new VectorTileSource({
      format: new MVT({ featureClass: Feature, idProperty: 'cad_pid' }),
      url: environment.apiUrl + 'cadastre/{z}/{x}/{y}',
      maxZoom: 14,
      minZoom: 10,
    }),
    visible: false,
    minZoom: 10,
    style: (feature) =>    {

return  new Style({fill: new Fill({
  color: 'rgba(255,255,255,0.01)',

}),
text: new Text({
  font: '12px Calibri,sans-serif',
  fill: new Fill({ color: '#000' }),
  stroke: new Stroke({ color: '#fff', width: 2 }),
  text: feature.getId() + "",}),
stroke: new Stroke({color: 'rgba(255,74,255,1)', width: 2})
})

    }
     });

  constructor(@Inject(DIALOG_DATA) private data) {
    this.form.patchValue(this.data);
    this.uploadUrl = environment.apiUrl + `/${this.data.propertyID}/files`;

    this.files = data.files.map((model) => {
      return {
        downloadUrl: this.propertiesService.getFileURL(
          data.propertyID,
          model.safeName
        ),
        ...model,
      };
    });

    this.isDirty = toSignal(
      this.form.valueChanges.pipe(map(() => this.form.dirty && this.form.valid))
    );


    const boundaryFeature = CovertWKTFeature(data.geom);
    this.spatialStore.state.setFeature(boundaryFeature);


    effect(() => {
      const feature = this.spatialStore.state.feature();

      if(feature == null)
        return;

      const geom = ConvertFeatureToWKT(feature);

      this.form.controls.geom.patchValue(geom);
      this.form.controls.geom.markAsDirty();
    })

    effect(() => {
      console.log(this.spatialToolStateMachine())
    })

    this.setupBaseMaps();

  }

  logy(message:any)
  {
    console.log(message)
  }

  onNoClick() {
    this.dialogRef.close(Err(new Error("Canceled")));
  }

  async setupBaseMaps()
  {
    const layers = (await this.baseMapsService.buildLayers()) as any;

    layers.forEach((layer) => {
      this.baseMapGroup.getLayers().push(layer);
    });
  }

  onYesClick() {
    const model = {};

    const changedValues = Object.keys(this.form.controls).filter(
      (key) => this.form.controls[key].dirty
    );

    // just showing a generic error message and preventing submission for the moment
    if (!this.form.valid) {
      this.toastService.showError('One or more inputs are invalid!');
      return;
    }

    for (let key of changedValues) {
      model[key] = this.form.controls[key].value;
    }

    this.dialogRef.close(Ok(model));
  }

  private saveFile(data: Blob, filename: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  onUpload($event: any) {
    let files: any[] = $event.originalEvent.body.map((model) => {
      return {
        downloadUrl: this.propertiesService.getFileURL(
          this.form.get('propertyID').value,
          model.safeName
        ),
        ...model,
      };
    });

    this.files.push(...files);
    this.toastService.showSuccess('File Uploaded Successfully!');
  }

  OnFileDelete(model: any) {
    this.propertiesService
      .deleteFile(this.form.get('propertyID').value, model)
      .subscribe(() => {
        const index = this.files.indexOf(model);
        if (index > -1) {
          this.files.splice(index, 1);
        }

        this.toastService.showSuccess('File Deleted Successfully!');
      });
  }

  hover(item:EditHistoryItem) {

    const {editHistoryLayer} = this.spatialStore;

    const source = editHistoryLayer.getSource();

    source.clear();

    source.addFeature(item.feature)

  }

  hoverEnd(edit: EditHistoryItem)
  {
    const {editHistoryLayer} = this.spatialStore;

    const source = editHistoryLayer.getSource();

    source.clear();

  }
}
