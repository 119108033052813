import { CdkPortal, CdkPortalOutlet, PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { Component, effect, inject, OnInit, viewChild } from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import { ActionPortalService } from '../../services/action-portal.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'action-portal',
    template: `
    <ng-template [cdkPortalOutlet]="portal()"></ng-template>
  `,
    imports: [PortalModule],
    host: {
        class: 'inline-flex w-full'
    }
})
export class ActionPortalComponent implements OnInit {

  private readonly actionPortalService: ActionPortalService = inject(ActionPortalService);


  portal = toSignal(this.actionPortalService.portal);

  constructor() { }

  ngOnInit() {
  }

}

