import { createInjectable } from 'ngxtension/create-injectable';
import { Feature, Map as OlMap } from 'ol';
import { MVT } from 'ol/format';
import VectorTileLayer from 'ol/layer/VectorTile';
import { Style, Fill, Stroke, Text } from 'ol/style';
import { environment } from 'src/environments/environment';
import VectorTileSource from 'ol/source/VectorTile';
import { EventsKey } from 'ol/events';
import { Geometry } from 'ol/geom';
import { unByKey } from 'ol/Observable';
import { BehaviorSubject, Subject } from 'rxjs';
import { bufferGeometry, Buffer,UnionJSTS, CovertWKTFeature } from 'src/app/shared/helpers/transformations';
import { inject } from '@angular/core';
import { SignalRService } from 'src/app/@core/services/signalR.service';

export const CadastreSelectTool = createInjectable(
  () => {

    const signalR = inject(SignalRService);


    const selectedFeature = new BehaviorSubject<Feature<Geometry>>(null);

    const featureAddedOrRemoved = (target: Map<any, Feature[]>) => {
      const selectedFeatures: Array<Feature<any>[]> = Array.from(target.values());

      const clonedFeatures = selectedFeatures.flatMap(featuresArr =>
        featuresArr.map(feature =>  feature.clone())
      );

        const feature =  UnionJSTS(clonedFeatures);

      selectedFeature.next(feature);
    }
    let eventsKeys = [];

    const handler: ProxyHandler<Map<any, Feature[]>> = {
      get(target, prop, receiver) {
        // 1) Special-case `set`
        if (prop === 'set') {
          return function (key: any, value: Feature[]) {
            console.log(`Adding key=${key}, value=${value}`);


            const result = Reflect.get(target, 'set', receiver).call(target, key, value);
            cadasterSelectionLayer.changed();
            featureAddedOrRemoved(target);
            return result
          };
        }

        // 2) Special-case `delete`
        if (prop === 'delete') {
          return function (key: any) {
            console.log(`Deleting key=${key}`);
            cadasterSelectionLayer.changed();

            const result = Reflect.get(target, 'delete', receiver).call(target, key);
            cadasterSelectionLayer.changed();
            featureAddedOrRemoved(target);
            return result
          };
        }

        if (prop === 'clear') {
          return function () {
            console.log(`clearing`);
            cadasterSelectionLayer.changed();

            const result = Reflect.get(target, 'clear', receiver).call(target);
            cadasterSelectionLayer.changed();
            featureAddedOrRemoved(target);
            return result
          };
        }

        // 3) For everything else, ensure methods are bound to `target`
        const value = Reflect.get(target, prop, receiver);

        // If it's a function on the Map, return a bound version
        if (typeof value === 'function') {
          return value.bind(target);
        }

        // Otherwise just return the property
        return value;
      }
    };

    const selectedParcels = new Proxy(
      new Map<any, Feature[]>(),
      handler
    ) as Map<any, Feature[]>;

    const cadasterLayer = new VectorTileLayer({
      properties: { title: 'Cadastre' },
      declutter: true,
      source: new VectorTileSource({
        format: new MVT({ featureClass: Feature, idProperty: 'cad_pid' }),
        url: environment.apiUrl + 'cadastre/{z}/{x}/{y}',
        maxZoom: 14,
        minZoom: 10,
      }),
      visible: false,
      minZoom: 10,
      style: (feature) => {
        return new Style({
          fill: new Fill({
            color: 'rgba(255,255,255,0.01)',
          }),
          text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({ color: '#000' }),
            stroke: new Stroke({ color: '#fff', width: 2 }),
            text: feature.getId() + '',
          }),
          stroke: new Stroke({ color: 'rgba(255,74,255,1)', width: 2 }),
        });
      },
    });

    const cadasterSelectionLayer = new VectorTileLayer({
      renderMode: 'vector',
      source: cadasterLayer.getSource(),
      style: (feature) => {
        if (selectedParcels.has(feature.getId())) {
          return new Style({
            fill: new Fill({
              color: 'rgba(242,247,250,0.01)',
            }),
            stroke: new Stroke({
              color: 'rgb(255,16,16)',
              width: 2,
            }),
          });
        }
        return null;
      },
    });

    cadasterSelectionLayer.setVisible(true);

    const checkRequiredZoom = (mapInstance: OlMap) => {
      const currentMapZoom =  mapInstance.getView().getZoom();

      const requiredZoom = cadasterLayer.getMinZoom()

      if(currentMapZoom <= requiredZoom)
      {
        cadasterLayer.setVisible(false);
        return;
      }
      cadasterLayer.setVisible(true);
    }

    const createClickEvent = (mapInstance: OlMap) => {

      const moveEventsKey = mapInstance.on('moveend', () => {

        checkRequiredZoom(mapInstance);
      });


      checkRequiredZoom(mapInstance);

      const clickEventKey = mapInstance.on(['click'], async (event: any) => {
        const clickedFeatures = await cadasterLayer.getFeatures(event.pixel);

        if (clickedFeatures.length == 0) return;

        if (clickedFeatures[0] == null) return;

        const firstFeatureFound = clickedFeatures[0] as Feature<Geometry>;

        const featureID = firstFeatureFound.getId();

        if (selectedParcels.has(featureID))
        {
          selectedParcels.delete(featureID);
          return;
        }

        const foundFeaturesWkt = await signalR.hubConnection.invoke("GetCadastreFeature", featureID);

        const matchingFeatures = foundFeaturesWkt.map(feature => CovertWKTFeature(feature));

        selectedParcels.set(featureID, matchingFeatures);
      });

      eventsKeys.push(...clickEventKey,moveEventsKey);
    };

    const cleanup = () => {

      selectedParcels.clear();
      cadasterLayer.setVisible(false);
      cadasterSelectionLayer.setVisible(false);
      removeClickEvent();
    }

    const removeClickEvent = () => {
      unByKey(eventsKeys);
      eventsKeys = [];
    };
    return { cadasterLayer,cadasterSelectionLayer, createClickEvent, removeClickEvent,cleanup, selectedFeature};
  },{providedIn: 'scoped'});
