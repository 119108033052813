import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Property } from '../../data-access/models/property.model';

@Component({
    selector: 'app-select-property-overlay',
    imports: [CommonModule, ReactiveFormsModule],
    templateUrl: './select-property-overlay.component.html',
    styleUrl: './select-property-overlay.component.css'
})
export class SelectPropertyOverlayComponent {

 properties = input<Property[]>([]);

@Output() propertySelected = new EventEmitter();

  /**
   *
   */
  constructor(){

  }

 select(property)
 {
  this.propertySelected.emit(property);
 }
}
