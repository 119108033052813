import { CommonModule } from '@angular/common';
import { Component, computed, effect, input, OnInit,output,model } from '@angular/core';
import { ScenarioService } from '../../data-access/scenario/scenario.service';
import { tuiItemsHandlersProvider } from '@taiga-ui/kit';
import { GetScenarioRequest } from '../../data-access/scenario/models/models';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {TuiDataList} from '@taiga-ui/core';
import {TuiDataListWrapper,} from '@taiga-ui/kit';
import {TuiSelectModule, TuiTextfieldControllerModule} from '@taiga-ui/legacy';
import { debug } from 'ngxtension/debug';
import { startWith } from 'rxjs';
@Component({
    selector: 'scenario-picker',
    template: `  <tui-select
  [formControl]="control"
>
Scenarios
<ng-template tuiDataList>
  <tui-data-list >
    <button tuiOption tuiOption  [value]="null">
      Reset
    </button>
    @for (scenario of scenarios(); track scenario.scenarioID) {
      <button tuiOption  [value]="scenario">
        {{ scenario.name }}
      </button>
    }
  </tui-data-list>
</ng-template>

</tui-select>`,
    imports: [CommonModule, TuiSelectModule,
        TuiDataListWrapper,
        TuiDataList,
        TuiTextfieldControllerModule, ReactiveFormsModule],
    providers: [tuiItemsHandlersProvider({
            stringify: (item: GetScenarioRequest) => `${item?.name}`,
        }),],
    host: {
        'class': 'flex-grow',
    }
})
export class ScenarioPickerComponent{

  scenarioChanged = output<GetScenarioRequest>();

  scenario = input.required<GetScenarioRequest>();

  scenarios = input.required<GetScenarioRequest[]>();

  disabled = input<boolean>(false);

  public control = new FormControl<GetScenarioRequest>(null);


  constructor() {

    effect(() => {
      const scenario =  this.scenario()

      console.log("scenario", scenario)

      this.control.setValue(scenario)
    })

    this.control.valueChanges.pipe(startWith(this.control.value)).subscribe(value =>
      {
        this.scenarioChanged.emit(value)
      }
      )



    effect(() => {
      const disabled = this.disabled();

      if(disabled)
        this.control.disable();
      else
      this.control.enable();
    })
   }




}
